import BaseView from "../BaseView";
import $ from "jquery";
import LoginService from "../../services/LoginService";
import Backbone from "backbone";

export default class LoginFormView extends BaseView {
  ui() {
    return {
      validateButton: "#validate",
    };
  }

  events() {
    return {
      "click @ui.validateButton": "validateForm",
    };
  }

  constructor(options) {
    super({ className: "login-form" });

    this.on("attach", () => {});
  }

  serializeData() {
    return {
      error: this.error,
      text: this.crewName,
    };
  }

  validateForm(e) {
    let crewName = $("#crew-name input").val();
    if (crewName == "") {
      this.showError();
    } else {
      LoginService.sendCrewName(crewName).then(
        function (res) {
          if (res) {
            window.localStorage.setItem("technicentreCrewName", res.id);
            Backbone.history.navigate("", { trigger: true });
          } else {
            this.showError();
          }
        }.bind(this)
      );
    }
  }

  showError() {
    this.error = "Veuillez ressaisir votre login";
    this.render();
  }
}
LoginFormView.templateId = "#logins-form-template";
