// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
// import * as ActiveStorage from "@rails/activestorage";
// import "channels";

// Rails.start();
// Turbolinks.start();
// ActiveStorage.start();

// console.log("APP STARTED");

/* eslint no-console:0 */

// import "babel-polyfill/dist/polyfill";
import FrontendApp from "../frontend/app";
import BufferedList from "buffered-list";
import Backbone from "backbone";
import TemplateCacheOverride from "../frontend/initializers/templateCacheOverride";

BufferedList.BufferedListView.setEventManager(Backbone.Events);

window.app = new FrontendApp({
  region: "#page-body",
});

app.once("before:start", TemplateCacheOverride);
window.onload = function () {
  app.start();
};
