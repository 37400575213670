import $ from 'jquery';
import moment from 'moment';

export default class ReportsService {

  static sendReports(model) {
    console.log('SEND REPORTS', model);
    return $.ajax({
      url: '/reports',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      method: 'POST',
      data: JSON.stringify(model),
      dataType: 'json',
      contentType: 'application/json'
    }).then(ReportsService.parseData).promise();
  }

  static parseData(data) {
    if (data.status && data.status == 403)
      return null;
    return data;
  }

  static getTrain(name, direction) {
    return $.ajax({
      url: '/get_train',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      method: 'GET',
      data: {train_name: name, direction: direction},
      dataType: 'json',
      contentType: 'application/json'
    }).promise();
  }

}