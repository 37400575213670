export default class ImageUtils {
  static readFile(file, callBack) {
    var reader = new FileReader();

    reader.onloadend = function () {
      ImageUtils.processFile(reader.result, file.type, callBack);
    };

    reader.onerror = function () {
      alert("There was an error reading the file!");
    };

    reader.readAsDataURL(file);
  }

  static processFile(dataURL, fileType, callBack) {
    var maxWidth = 800;
    var maxHeight = 800;

    var image = new Image();
    image.src = dataURL;
    var canvas = null;

    image.onload = function () {
      var width = image.width;
      var height = image.height;
      var shouldResize = width > maxWidth || height > maxHeight;

      if (!shouldResize) {
        //sendFile(dataURL);
        //return;
      }

      var newWidth;
      var newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      var canvas = document.createElement("canvas");

      canvas.width = newWidth;
      canvas.height = newHeight;

      var context = canvas.getContext("2d");

      context.drawImage(this, 0, 0, newWidth, newHeight);
      dataURL = canvas.toDataURL(fileType);
      callBack(canvas, dataURL);
      //sendFile(dataURL);
    };

    image.onerror = function () {
      alert("There was an error processing your file!");
    };
  }
}
