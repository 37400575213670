import Marionette from "backbone.marionette";
import Handlebars from "../handlebars.bower";
import _ from "underscore";
import $ from "jquery";

Marionette.TemplateCache.prototype.loadTemplate = function (id) {
  console.log("loadTemplate", id);
  var element = $(id).get(0);
  if (!element) return "";
  return _.unescape(element.textContent);
};

Marionette.TemplateCache.prototype.compileTemplate = function (
  rawTemplateContent
) {
  return Handlebars.compile(rawTemplateContent);
};

export default function (app) {
  var debugmode = true;
  var bindedViewCount = 0;

  if (debugmode) console.groupCollapsed("load");

  //
  console.log("TEMPLATES DIVS", $("body > templates > div"));
  $("body > templates > div").each(function () {
    var id = this.getAttribute("id");
    var template = Marionette.TemplateCache.get("#" + id);
    var templateContent;
    if (/^svg/.test(id)) {
      // precompile svg templates
      templateContent = template();
      template = function () {
        return templateContent;
      };
    }
    console.log("TEST", id);
    Handlebars.registerPartial(id, template);
  });

  if (debugmode) {
    console.groupEnd("load");
  }
}
