import BaseView from "../BaseView";
import $ from "jquery";
import CarRecap from "../../models/carRecap";
import ReportsRecapCollectionView from "./ReportsRecapCollectionView";

export default class RecapView extends BaseView {
  static regions() {
    return {
      reportsRecap: "#recap-list",
    };
  }

  childViewEvents() {
    return {
      "select:item": "itemSelected",
    };
  }

  ui() {
    return {
      recapSendButton: "#send-recap",
      closePopupButton: "#close-popup",
      validateCodeButton: "#validate-code",
    };
  }

  events() {
    return {
      "click @ui.recapSendButton": "recapSendButtonClick",
      "click @ui.closePopupButton": "recapSendButtonClick",
      "click @ui.validateCodeButton": "validateCodeButtonClick",
    };
  }

  constructor(options) {
    super({ className: "recap-view" });
    this.reports = options.reports;
    this.train = options.train;
    this.enterCode = options.enterCode || false;
    this.validateEndQrCode = options.validateEndQrCode;

    this.addRegions(RecapView.regions());
  }

  onRender() {
    const cars = this.train.cars;
    this.recapCollectionView = new ReportsRecapCollectionView();
    for (let i = 0; i <= cars.length - 1; i++) {
      let car = cars[i];
      let position =
        i == cars.length - 1 ? "last" : i == 0 ? "first" : "middle";
      let carReports = this.reports.filter(function (report) {
        return report.carId == car.id;
      });
      this.recapCollectionView.collection.add(
        new CarRecap({ car: car, position: position, reports: carReports })
      );
    }
    this.showChildView("reportsRecap", this.recapCollectionView);
  }

  itemSelected(childView) {
    this._parentView().goToMainView(true, childView.model.car);
  }

  serializeData() {
    return {
      enterCode: this.enterCode,
      error: this.error,
    };
  }

  _disableSubmitButtonWhile(promise) {
    const _this = this;
    _this
      .$("#validate-code,#send-recap,#close-popup")
      .attr("disabled", "disabled");
    function promiseCallback() {
      _this.$("#validate-code,#send-recap,#close-popup").removeAttr("disabled");
    }
    promise.then(promiseCallback, promiseCallback);
    return promise;
  }

  recapSendButtonClick(e) {
    if (this.validateEndQrCode) {
      this.enterCode = !this.enterCode;
      this.render();
    } else {
      this._disableSubmitButtonWhile(this._parentView().sendRecap("generic"));
    }
  }

  validateCodeButtonClick(e) {
    const code = $("#code-input").val();
    if (code) {
      this._disableSubmitButtonWhile(this._parentView().sendRecap(code));
    } else {
      this.error = "error";
      this.render();
    }
  }
}

RecapView.templateId = "#reports-recap-template";
