import moment from 'moment';
export default class Train {

  constructor(trainobj, cars){
    let train = trainobj || {};
    this.id = (train._id || {}).$oid;
    this.lineid = (train.line_id || {}).$oid;
    this.name = train.name;
    this.cars = (cars || []).map(function(carobj) {
      let car = carobj || {}
      return {
        id: car._id != null ? car._id.$oid : '',
        name: car.name,
        number: car.number,
        trainId: car.train_id != null ? car.train_id.$oid : ''
      }
    });
  }

  getIndex(carName) {
    return this.cars.map(function(car) {return car.name}).indexOf(carName)
  }

  static getTrainTest() {
    return {
      name: 'train 1',
      cars: [ {name: 'ABC', number: 1}, 
              {name: 'DEF', number: 2},
              {name: 'GHI', number: 3}]
    }
  }

}