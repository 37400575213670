import moment      from 'moment';
import Backbone    from 'backbone';

export default class CarRecap extends Backbone.Model {

  constructor(options){
    super()
    this.car = options.car
    this.position = options.position
    this.reports = options.reports
    console.log('this reports carrecap', this.reports)
  }

  hasReportType(reportType) {
    let res = this.reports.filter(function(report) {
      console.log('report type', report.type, reportType)
      return report.type == reportType;
    }).length > 0
    console.log('HAS TYPE', reportType, res);
    return res;
  }

}