import BaseView from "../BaseView";
import $ from "jquery";

export default class HeaderView extends BaseView {
  static regions() {
    return {};
  }

  ui() {
    return {
      closeButton: "#close-button",
    };
  }

  events() {
    return {
      "click @ui.closeButton": "closeButtonClick",
    };
  }

  constructor(options) {
    super(options);
    this.title = this.getOption("title");
    this.date = this.getOption("date");
    this.hour = this.getOption("hour");
    this.closeOption = this.getOption("closeOption");
    if (this.hour) {
      this.intervalId = setInterval(
        function () {
          this.setHour();
          //this.render();
        }.bind(this),
        3000
      );
    }
  }

  serializeData() {
    return {
      title: this.title,
      date: this.date,
      hour: this.hour,
      closeOption: this.closeOption != null,
    };
  }

  setHour() {
    var today = new Date();
    var min = today.getMinutes();
    var hour = today.getHours();
    hour = hour < 10 ? "0" + hour : hour;
    min = min < 10 ? "0" + min : min;

    this.hour = hour + ":" + min;
  }

  onBeforeDestroy() {
    clearInterval(this.intervalId);
  }

  closeButtonClick() {
    this.closeOption();
  }
}
HeaderView.templateId = "#headers-title-header-template";
