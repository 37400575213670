import Backbone from "backbone";
import Marionette from "backbone.marionette";
import _ from "underscore";
import routers from "./routers/routers";
import "./css/index.scss";

window.Marionette = Marionette;

export default class FrontendApp extends Marionette.Application {
  constructor(options) {
    super(options);
    this.routers = [];
    console.log("Front App constructor");
  }

  start() {
    this._loadRoutes();
    super.start();
    console.log("Application Started");
    this._startHistory();
  }

  _startHistory() {
    if (!(Backbone.history && !Backbone.History.started)) return;
    console.log("start history");
    Backbone.history.start({ pushState: false, root: "", hashChange: true });
  }

  _loadRoutes() {
    this.routers = _.map(routers, function (RouterConstructor) {
      return new RouterConstructor();
    });
  }
}
