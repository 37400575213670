import { View, TemplateCache } from "backbone.marionette";

export default class BaseView extends View {
  static regions() {
    return {
      header: "#header-region",
      body: "#body-region",
    };
  }

  constructor(options) {
    super(options);
    this.template = null;
    if (this.constructor.templateId) {
      this.template = TemplateCache.get(this.constructor.templateId);
    }

    this.addRegions(BaseView.regions());
  }
}
