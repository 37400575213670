import BaseView from "../BaseView";
import $ from "jquery";
import ImageUtils from "../../services/imageUtils";
import CarReport from "../../models/carReport";

export default class ReportView extends BaseView {
  ui() {
    return {
      takePictureButton: "#new-picture-block",
      reportTypeDiv: "#report-type div",
      photoInput: "#take-picture-input",
      descriptionInput: "#description-cont",
      deletePictureButton: ".delete-picture",
      validateReportButton: "#validate",
      deleteReportButton: "#delete",
      closeErrorButton: ".cross-close svg",
      popup: "#error-pop",
      pagebody: "#body-region",
    };
  }

  events() {
    return {
      "click @ui.takePictureButton": "takePictureButtonClick",
      "click @ui.reportTypeDiv": "reportTypeDivClick",
      "change @ui.photoInput": "photoInputChanged",
      "change @ui.descriptionInput": "descriptionInputChanged",
      "click @ui.deletePictureButton": "deletePictureButtonClick",
      "click @ui.validateReportButton": "validateReportClick",
      "click @ui.deleteReportButton": "deleteReportButtonClick",
      "click @ui.closeErrorButton": "closeErrorButtonClick",
      "click @ui.popup": "popupClick",
      "click @ui.pagebody": "pagebodyClick",
    };
  }

  constructor(options) {
    super({ className: "report-car-view" });
    this.carReport = options.carReport;
    let carReportObj = this.carReport || {};
    this.car = options.car;
    this.train = options.train;
    this.pictures = carReportObj.pictures || [];
    this.reportType = carReportObj.type || options.reportType;
    this.description = carReportObj.description;
    this.error = null;
  }

  initialize() {
    $("body").bind("click", this.pagebodyClick.bind(this));
  }

  serializeData() {
    return {
      trainNumber: this.train,
      carType: "first", // 'last', 'middle'
      carNumber: this.car.number,
      carName: this.car.name,
      pictures: this.pictures,
      comfort: this.reportType == "comfort",
      security: this.reportType == "security",
      showPictures: this.pictures.length < 3,
      description: this.description,
      error: this.error,
    };
  }

  takePictureButtonClick() {
    $("#take-picture-input").trigger("click");
  }

  reportTypeDivClick(e) {
    let $elem = $(e.currentTarget);
    $("#report-type div").removeClass("active");
    $elem.addClass("active");
    this.reportType = $elem.data("type");
  }

  validateReport() {
    this._parentView().addReport();
  }

  photoInputChanged(e) {
    var file = e.target.files[0];

    if (file) {
      if (/^image\//i.test(file.type)) {
        ImageUtils.readFile(
          file,
          function (canvas, dataUrl) {
            this.pictures.push(dataUrl);
            this.render();
          }.bind(this)
        );
      } else {
        console.log("Failed to read Image");
      }
    }
  }

  descriptionInputChanged(e) {
    this.description = $(e.currentTarget).val();
  }

  deletePictureButtonClick(e) {
    let index = $(e.currentTarget).data("index");
    $("#picture-" + index).remove();
    this.pictures.splice(index, 1);
    this.render();
  }

  validateReportClick(e) {
    let description = $("#description-cont").val();
    if (description == null || description == "" || this.reportType == null) {
      this.error =
        "Sélectionnez un type de rapport, et décrivez le problème rencontré";
      e.stopPropagation();
      this.render();
    } else {
      let report = new CarReport({
        reportType: this.reportType,
        pictures: this.pictures,
        description: description,
        carId: this.car.id,
        trainNumber: this.train,
      });
      this._parentView().addReport(report, this.carReport);
    }
  }

  deleteReportButtonClick() {
    this._parentView().deleteReport(this.carReport);
  }

  closeErrorButtonClick() {
    this.error = null;
    this.render();
  }

  popupClick(e) {
    e.stopPropagation();
  }

  pagebodyClick(e) {
    if (this.error && this.error != "") {
      e.stopPropagation();
      this.error = null;
      this.render();
    }
  }
}
ReportView.templateId = "#reports-car-view-template";
