import BaseView from "../BaseView";

export default class ReportPreview extends BaseView {
  triggers() {
    return {
      "click .preview": "select:item",
    };
  }

  constructor(options) {
    super({ className: "reportprev" });
    this.model = options.model;
  }

  serializeData() {
    return {
      picture: this.model.pictures[0] || "/no-image.svg",
      reportType: this.model.type,
      description: this.model.description,
      nbPictures: this.model.pictures.length,
      comfort: this.model.type == "comfort",
      reportTypeLabel: this.model.type == "comfort" ? "confort" : "sécurité",
    };
  }
}
ReportPreview.templateId = "#reports-preview-template";
