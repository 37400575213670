import { Router } from "backbone";
import LoginLayout from "../views/login/LoginLayout";
import ErrorLayout from "../views/error/ErrorLayout";

export default class HomeRouter extends Router {
  static routes() {
    return {
      "!login": "login",
      "!error": "errorPage",
    };
  }

  constructor() {
    super({ routes: HomeRouter.routes() });
  }

  login() {
    app.showView(new LoginLayout({ title: "CONNEXION" }));
  }

  errorPage() {
    app.showView(new ErrorLayout({ error: window.gon.error }));
  }
}
