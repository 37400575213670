import BaseView from "../BaseView";
import $ from "jquery";
import ReportsService from "../../services/ReportsService";
import TrainsService from "../../services/TrainsService";

export default class GenericTrainView extends BaseView {
  ui() {
    return {
      validateButton: "#validate-train",
    };
  }

  events() {
    return {
      "click @ui.validateButton": "validateButtonClick",
    };
  }

  constructor(options) {
    super(options);
    this.trainNumbers = [];
    TrainsService.getAll().then((trains) => {
      this.trainNumbers = trains;
      this.renderTrainOptions(trains);
    });
  }

  serializeData() {
    return {
      carNumber: this.carNumber,
      direction: this.direction,
      error: this.error,
    };
  }

  onRender() {
    if (this.trainNumbers) {
      this.renderTrainOptions();
    }
  }

  validateButtonClick() {
    const trainNumber = $("#train-number").val() || "";
    const direction = $("#direction").val() || "";

    if (trainNumber == "" || direction == "") {
      this.$("#error").text((this.error = "Veuillez remplir tous les champs"));
      this.render();
    } else {
      this.redirectToReportCreation(trainNumber, direction);
    }
  }

  redirectToReportCreation(trainNumber, direction) {
    return ReportsService.getTrain(trainNumber, direction)
      .then(function (data) {
        let url = data.report_url;
        let paramJoiner = "?";
        if (url.includes("?")) paramJoiner = "&";
        url += paramJoiner + "validate_end=0";
        return (window.location = url);
      })
      .catch(function () {
        return null;
      });
  }

  renderTrainOptions(trainNumbers = this.trainNumbers) {
    const options = trainNumbers.map(this.renderTrainOption, this);
    options.unshift('<option value="">N° DE TRAIN</option>');
    $("#train-number").html(options);
  }

  renderTrainOption({ label }) {
    return `<option value="${label}">${label}</option>`;
  }
}
GenericTrainView.templateId = "#reports-generic-view-template";
