import BaseView from "../BaseView";
import $ from "jquery";
import LoginFormView from "../login/LoginFormView";
import HeaderView from "../headers/HeaderView";

export default class ErrorLayout extends BaseView {
  constructor(options) {
    super({ className: "single-page" });
    this.error = options.error;

    this.headerView = new HeaderView({ title: "ERREUR" });

    this.on("attach", () => {
      this.showChildView("header", this.headerView);
    });
  }

  serializeData() {
    return {
      error: this.error,
    };
  }
}
ErrorLayout.templateId = "#errors-layout-template";
