import BaseView from "../BaseView";
import $ from "jquery";
import ReportsCollectionView from "./reportsCollectionView";

export default class MainView extends BaseView {
  static regions() {
    return {
      reportsPreview: "#reports-preview-region",
    };
  }

  ui() {
    return {
      nextCarButton: "#next-car",
      prevCarButton: "#prev-car",
      makeReportButton: "#make-report",
      finalizeReportButton: "#finalize",
      backToRecapButton: "#back-recap",
    };
  }

  events() {
    return {
      "click @ui.nextCarButton": "nextCarButtonClick",
      "click @ui.prevCarButton": "nextCarButtonClick",
      "click @ui.makeReportButton": "makeReportButtonClick",
      "click @ui.finalizeReportButton": "finalizeReportButtonClick",
      "click @ui.backToRecapButton": "backToRecapButtonClick",
    };
  }

  childViewEvents() {
    return {
      "select:item": "itemSelected",
    };
  }

  constructor(options) {
    super(options);
    this.car = this.getOption("car");
    this.train = this.getOption("train");
    this.index = this.getOption("index");
    this.lengthCars = this.getOption("lengthCars");
    this.fromRecap = this.getOption("fromRecap");
    this.reports = this.getOption("reports");

    this.reportsCollectionView = new ReportsCollectionView();
    for (let i = this.reports.length - 1; i >= 0; i--) {
      let report = this.reports[i];
      this.reportsCollectionView.collection.add(report);
    }

    this.addRegions(MainView.regions());

    this.on("attach", () => {
      this.showChildView("reportsPreview", this.reportsCollectionView);
    });
  }

  serializeData() {
    return {
      trainNumber: this.train,
      carType: "first", // 'last', 'middle'
      carNumber: this.car.number,
      carName: this.car.name,
      first: this.index == 0,
      last: this.index == this.lengthCars - 1,
      fromRecap: this.fromRecap,
      hasReports: this.reports.length > 0,
    };
  }

  nextCarButtonClick(e) {
    let $elem = $(e.currentTarget)[0];
    this._parentView().carNavigate($elem.id);
  }

  makeReportButtonClick() {
    this._parentView().makeReport();
  }

  finalizeReportButtonClick() {
    this._parentView().goToRecap();
  }

  backToRecapButtonClick() {
    this._parentView().goToRecap();
  }

  itemSelected(reportPreview) {
    this._parentView().editReport(reportPreview.model);
  }
}
MainView.templateId = "#reports-main-view-template";
