import $ from 'jquery';

export default class TrainsService {

  static getAll() {
    return $.ajax({
      url: '/trains',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      contentType: 'application/json'
    }).promise();
  }

}