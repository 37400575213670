import { CollectionView } from 'backbone.marionette';
import Backbone from 'backbone';
import ReportPreview from './ReportPreview';

export default class ReportsCollectionView extends CollectionView {
  constructor(options) {
    super({className: 'reports-collection-view'});
    this.childView  = ReportPreview;
    this.collection = new Backbone.Collection();
    this.tagName    = 'ul';
    this.className  = 'list-unstyled';
  }
}