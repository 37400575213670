import BaseView from "../BaseView";
import HeaderView from "../headers/HeaderView";
import MainView from "./MainView";
import ReportView from "./ReportView";
import RecapView from "./RecapView";
import Train from "../../models/train";
import ReportsService from "../../services/ReportsService";
import SimpleView from "./SimpleView";
import GenericTrainView from "./GenericTrainView";

export default class ReportLayout extends BaseView {
  constructor(options) {
    super({ className: "main-layout" });
    this.dateInfo = this.getDate();
    this.currentCar = window.gon.currentCar;
    this.reportDate = window.gon.start;
    this.train = new Train(window.gon.train, window.gon.cars);
    this.reports = [];
    this.index =
      this.currentCar != null ? this.train.getIndex(this.currentCar.name) : 0;
    this.headerView = new HeaderView({
      title: this.train.name ? "RAME N° " + this.train.name : "CONNEXION",
      date: this.train.name ? this.dateInfo.date : null,
      hour: this.train.name ? this.dateInfo.hour : null,
    });
    this.validateEndQrCode = options.validateEndQrCode;

    this.on("attach", () => {
      this.showChildView("header", this.headerView);
      if (this.currentCar != null) this.goToMainView();
      else this.getGenericTrain();
    });
  }

  getGenericTrain() {
    this.bodyView = new GenericTrainView({
      className: "generic-view",
      parent: this,
    });
    this.showChildView("body", this.bodyView);
  }

  goToMainView(fromRecap, carModel) {
    this.fromRecap = fromRecap;
    let currentCar = this.train.cars[this.index];
    if (carModel) {
      currentCar = this.train.cars.find(function (car) {
        return car.id == carModel.id;
      });
      this.index = this.train.cars
        .map(function (car) {
          return car.id;
        })
        .indexOf(carModel.id);
    }
    let reports = this.reports.filter(function (report) {
      return report.carId == currentCar.id;
    });
    this.bodyView = new MainView({
      className: "main-view",
      fromRecap: this.fromRecap,
      reports: reports,
      lengthCars: this.train.cars.length,
      car: this.train.cars[this.index],
      index: this.index,
      train: this.train,
      parent: this,
    });
    this.showChildView("body", this.bodyView);
  }

  getDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var min = today.getMinutes();
    var hour = today.getHours();

    dd = dd < 10 ? "0" + dd : dd;
    mm = mm < 10 ? "0" + mm : mm;
    hour = hour < 10 ? "0" + hour : hour;
    min = min < 10 ? "0" + min : min;

    today = mm + "/" + dd + "/" + yyyy;
    var now = hour + ":" + min;
    return { date: today, hour: now };
  }

  carNavigate(direction) {
    if (direction == "next-car") this.index += 1;
    else this.index -= 1;
    this.goToMainView();
  }

  makeReport(carReport) {
    this.bodyView = new ReportView({
      carReport: carReport,
      car: this.train.cars[this.index],
      train: this.train,
      parent: this,
    });
    this.headerView = new HeaderView({
      title: "SIGNALEMENT",
      closeOption: this.closeReport.bind(this),
    });

    this.showChildView("body", this.bodyView);
    this.showChildView("header", this.headerView);
  }

  closeReport() {
    this.goToMainView();
    this.headerView = new HeaderView({
      title: "RAME N° " + this.train.name,
      date: this.dateInfo.date,
      hour: this.dateInfo.hour,
    });
    this.showChildView("header", this.headerView);
  }

  addReport(report, oldReport) {
    if (oldReport) {
      let index = this.reports.indexOf(oldReport);
      this.reports[index] = report;
    } else {
      this.reports.push(report);
    }
    this.goToMainView(this.fromRecap);
  }

  goToRecap(enterCode) {
    this.bodyView = new RecapView({
      reports: this.reports,
      train: this.train,
      parent: this,
      enterCode: enterCode,
      validateEndQrCode: this.validateEndQrCode,
    });
    this.showChildView("body", this.bodyView);
    this.headerView = new HeaderView({ title: "EXAMEN JOURNALIER" });
    this.showChildView("header", this.headerView);
  }

  editReport(carReport) {
    this.makeReport(carReport);
  }

  deleteReport(carReport) {
    if (carReport) {
      let index = this.reports.indexOf(carReport);
      this.reports.splice(index, 1);
    }
    this.goToMainView();
    this.headerView = new HeaderView({
      title: "RAME N° " + this.train.name,
      date: this.dateInfo.date,
      hour: this.dateInfo.hour,
    });
    this.showChildView("header", this.headerView);
  }

  sendRecap(code) {
    console.log("SEND RECAP YOUHOU");
    let model = {
      crewId: window.localStorage.getItem("technicentreCrewName"),
      carId: this.currentCar._id.$oid,
      reportDate: this.reportDate,
      reports: this.reports.map(function (report) {
        return {
          carId: report.carId,
          type: report.type,
          description: report.description,
          pictures: report.pictures,
        };
      }),
      qrCode: code,
      trainId: this.train.id,
    };
    const promise = ReportsService.sendReports(model);
    return promise
      .then(
        function (d) {
          this.reportsSent(d);
        }.bind(this)
      )
      .catch(this.reportsSentFailed.bind(this));
  }

  reportsSent(data) {
    console.log("REPORTS SENT", data);
    this.bodyView = new SimpleView({ result: data != null, parent: this });
    this.showChildView("body", this.bodyView);
    this.headerView = new HeaderView({ title: "CONFIRMATION ENVOI EJ" });
    this.showChildView("header", this.headerView);
  }

  reportsSentFailed(xhr) {
    const errObject = JSON.parse(xhr.responseText || "{}");
    console.log("REPORTS SENT FAILED");

    this.bodyView = new SimpleView({
      result: false,
      parent: this,
      errMessage: errObject.message || null,
    });
    this.showChildView("body", this.bodyView);
    this.headerView = new HeaderView({ title: "CONFIRMATION ENVOI EJ" });
    this.showChildView("header", this.headerView);
  }

  tryAgain() {
    let reg = /validate_end=([0-1])/i.exec(location.search);
    this.goToRecap(+(reg ? reg[1] : 0) !== 0);
  }
}
ReportLayout.templateId = "#reports-layout-template";
