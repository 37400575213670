import BaseView from "../BaseView";

export default class SimpleView extends BaseView {
  ui() {
    return {
      TryAgainButton: "#try-again",
    };
  }

  events() {
    return {
      "click @ui.TryAgainButton": "TryAgainButtonClick",
    };
  }

  constructor(options) {
    options.className = "result-view";
    super(options);
    this.result = options.result;
  }

  serializeData() {
    return {
      result: this.result,
      errMessage: this.getOption("errMessage"),
    };
  }

  TryAgainButtonClick() {
    this._parentView().tryAgain();
  }
}

SimpleView.templateId = "#reports-simple-template";
