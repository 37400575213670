import moment from 'moment';
import Backbone    from 'backbone';

export default class CarReport extends Backbone.Model {

  constructor(options){
    super()
    console.log('car report options', options)
    this.type = options.reportType
    this.id = options.id;
    this.carId = options.carId;
    this.trainNumber = options.trainNumber;
    this.description = options.description;
    this.moment = moment();
    this.pictures = options.pictures || [];
  }

  getDate() {
    return this.moment.format('DD/MM/YYYY')
  }

  getHour() {
    return this.moment.format('HH:mm')
  }

  static getCarReportsTest() {
    let cr1 = new CarReport({
      type: 'security',
      id: 'fheir',
      carId: 'hforhi',
      trainNumber: 'iroe',
      description: 'fhoerhorehroehiofrehiofrehior',
      moment: moment(),
      pictures: []
    })
    let cr2 = new CarReport({
      type: 'comfort',
      id: 'fhfehizeir',
      carId: 'hforhi',
      trainNumber: 'iroe',
      description: 'ieieooreioriojezzb',
      moment: moment(),
      pictures: []
    })

    let cr3 = new CarReport({
      type: 'security',
      id: 'fhehzeih789ir',
      carId: 'hforhi',
      trainNumber: 'iroe',
      description: 'lalala maison armoire',
      moment: moment(),
      pictures: []
    })

    let cr4 = new CarReport({
      type: 'comfort',
      id: 'fhedzhufe6786ir',
      carId: 'hforhi',
      trainNumber: 'iroe',
      description: 'chaton disney',
      moment: moment(),
      pictures: []
    })

    return [cr1, cr2, cr3, cr4]
  }

}