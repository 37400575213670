import BaseView from "../BaseView";
import LoginFormView from "./LoginFormView";
import HeaderView from "../headers/HeaderView";

export default class LoginLayout extends BaseView {
  constructor(options) {
    super(options);

    this.headerView = new HeaderView({ title: "CONNEXION" });
    this.loginFormView = new LoginFormView();

    this.on("attach", () => {
      this.showChildView("header", this.headerView);
      this.showChildView("body", this.loginFormView);
    });
  }
}
LoginLayout.templateId = "#logins-layout-template";
