import $ from "jquery";
import moment from "moment";

export default class LoginService {
  static sendCrewName(teamName, callback) {
    if (teamName === undefined) {
      teamName = "";
    }

    return $.ajax({
      url: "/crew/" + teamName,
    })
      .then(LoginService.parseData)
      .catch(function () {
        return null;
      })
      .promise();
  }

  static parseData(data) {
    if (data.status && data.status == 401) return null;
    return data;
  }
}
