import {Router} from 'backbone';

export default class AbsUserLoggedRouter extends Router {

  goToLogin() {
    location.hash = '!login';
  }

  isLoggedIn() {
    return !!window.localStorage.getItem('technicentreCrewName')
  }

  execute(callback, args, name) {
    console.log('AbsUserLoggedRouter#isLoggedIn() == %s', this.isLoggedIn());
    if (!this.isLoggedIn()) {
      this.goToLogin();
      return false;
    }
    return super.execute(callback, args, name);
  }
}
