import BaseView from "../BaseView";

export default class RecapItem extends BaseView {
  triggers() {
    return {
      "click .car-recap": "select:item",
    };
  }

  constructor(options) {
    super({ className: "reportprev" });
    this.model = options.model;
    this.reports = options.model.reports;
    this.position = options.model.position;
  }

  serializeData() {
    return {
      carNumber: this.model.car.number,
      comfort: this.model.hasReportType("comfort"),
      security: this.model.hasReportType("security"),
      clean: this.reports.length == 0,
      carPosition: this.position,
    };
  }
}
RecapItem.templateId = "#reports-recap-item-template";
