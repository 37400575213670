import { CollectionView } from 'backbone.marionette';
import Backbone from 'backbone';
import RecapItem from './RecapItem';

export default class ReportsRecapCollectionView extends CollectionView {
  constructor(options) {
    super({className: 'reports-recap-collection-view'});
    this.childView = RecapItem;
    this.collection = new Backbone.Collection();
    this.tagName = 'ul';
    this.className = 'list-unstyled';
  }
}