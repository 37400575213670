import AbsUserLoggedRouter from "./AbsUserLoggedRouter";
import ReportLayout from "../views/report/ReportLayout";

export default class FrontRouter extends AbsUserLoggedRouter {
  static routes() {
    return {
      "": "newReport",
      "!": "newReport",
      "!generic": "genericReport",
      "!reports/new": "newReport",
    };
  }

  constructor() {
    super({ routes: FrontRouter.routes() });
  }

  redirectToIndex() {
    var name = window.localStorage.getItem("technicentreCrewName");
    var car = window.gon.currentCar;
    var error = window.gon.error;
    if (car == null || error) {
      window.gon.error = "Aucune rame ne correspond à cette url";
      location.hash = "!error";
    } else if (name) {
      this.newReport();
    } else {
      this.goToLogin();
    }
  }

  genericReport() {
    app.showView(
      new ReportLayout({
        title: "RAME N°",
      })
    );
  }

  newReport() {
    if (window.gon.is_generic) {
      location.hash = "!generic";
      return;
    }
    if (!window.gon.currentCar) {
      window.gon.error = "Aucune rame ne correspond à cette url";
      location.hash = "!error";
      return;
    }
    app.showView(
      new ReportLayout({
        title: "RAME N°",
        validateEndQrCode: this._shouldValidateEndQrCode(),
      })
    );
  }

  /**
   * Returns false if the params ?validate_end=0
   * Else returns true
   * @returns {Boolean}
   */
  _shouldValidateEndQrCode() {
    const params = this._parseParams();
    if ("validate_end" in params && params.validate_end === "0") {
      return false;
    }
    return true;
  }

  /**
   * Parse query parameters and return them as object
   * @returns {Object.<String, String>}
   */
  _parseParams() {
    const queryParams = location.search.toString().slice(1).split("&");
    const output = {};
    for (var index = 0; index < queryParams.length; index++) {
      const keyValuePair = queryParams[0].split("=");
      output[keyValuePair[0]] = decodeURIComponent(keyValuePair[1]);
    }
    return output;
  }
}
